import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import FormContainer from '../../ui/FormContainer/FormContainer';
import { Form } from 'react-final-form';
import { createCourse } from '../../api/courses/courses';
import {
    assignedQualificationOptions,
    trainingTypeOptions,
    issuedDocumentsOptions
} from 'messages/options';
import Button from '../../ui/Button/Button';
import { getCommitteesOptions } from '../../api/committees/committees';
import { makeCreateFormFunc } from 'utils/api';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import { getProfessionsOptions } from 'api/professions/professions';
import { Field, Select } from './components/fields';
import { DisciplineField } from './components/DisciplineField';

const handleFormSubmit = makeCreateFormFunc(createCourse, 'Курс создан!');

const CreateCourse = () => {
    return (
        <>
            <Headline>Создать курс</Headline>
            <Form onSubmit={handleFormSubmit}>
                {({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <Field
                            name="title"
                            label="Название курса"
                            placeholder="Название курса"
                            required
                        />
                        <Select
                            name="professionId"
                            label="Название профессии (из справочника)"
                            placeholder="Выберите профессию"
                            loader={getProfessionsOptions}
                            onSearch={getProfessionsOptions}
                            required
                        />
                        <Select
                            name="trainingType"
                            label="Вид обучения"
                            options={trainingTypeOptions}
                            required
                        />
                        <Select
                            name="assignedQualification"
                            label="Присваемая квалификация"
                            options={assignedQualificationOptions}
                            required
                        />
                        <DisciplineField />
                        <Field
                            name="hoursCount"
                            label="Количество часов"
                            placeholder="Введите число"
                            type="number"
                            required
                        />
                        <Select
                            name="issuedDocuments"
                            required
                            multiple
                            label="Выдаваемый документ"
                            options={issuedDocumentsOptions}
                        />
                        <Select // выпадашка
                            name="committeeId"
                            required
                            label="Комиссия"
                            loader={getCommitteesOptions}
                        />
                        <Field
                            name="validityPeriodDocument"
                            label="Срок действия документа (кол-во лет)"
                            placeholder="Введите число"
                            type="number"
                            required
                        />
                        <Field
                            name="price"
                            label="Стоимость обучения"
                            placeholder="Введите стоимость"
                            required
                            min="0"
                            type="number"
                        />
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonsContainer>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(CreateCourse));
