/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useAuth, useCheckAuth } from './Auth.context';
import Skeleton from 'components/Skeleton/Skeleton';
import { UserType } from 'api/api';
import { UserInfo } from 'api/users/users.types';

type AuthPageProps = {
    user: UserInfo;
};

export const withAuth =
    (
        Page: FC<AuthPageProps>,
        skeleton?: React.ReactNode,
        userType: UserType = UserType.Operator
    ) =>
    // eslint-disable-next-line react/display-name
    (props: any) => {
        const { logined, user } = useAuth(userType);

        if (!logined) {
            return <>{skeleton}</> || <Skeleton />;
        }

        return <Page {...props} user={user} />;
    };

export type CheckAuthPageProps = {
    userType: UserType;

    user: UserInfo;
};

export const withCheckAuth =
    (Page: FC<CheckAuthPageProps>, skeleton?: React.ReactNode) =>
    // eslint-disable-next-line react/display-name
    (props: any) => {
        const { logined, userType, user } = useCheckAuth();

        if (!logined) {
            return <>{skeleton}</> || <Skeleton />;
        }

        return <Page {...props} userType={userType} user={user} />;
    };
