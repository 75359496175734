import { useCallback } from 'react';
import { editOrganization } from 'api/organizations/organizations'; // Импортируем наши запросы
import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import Button from 'ui/Button/Button';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { OperatorFullInfo } from 'api/organizations/operators.types';
import { useUser } from 'context/User';
import { OPERATOR_EDIT_PROPS } from '../constants';

const Field = FormField<OperatorFullInfo>;

const OperatorEditForm = () => {
    const user = useUser();
    const getter = useCallback(() => {
        if (!user) {
            throw new Error('User was not loaded');
        }
        return Promise.resolve(user);
    }, [user]);

    return (
        <EditForm
            getter={getter} // Получаем данные организации
            saver={editOrganization} // Сохраняем изменения
            successMessage="Организация успешно обновлена"
        >
            {({ submitting }) => (
                <>
                    <Headline>Редактировать оператора</Headline>
                    <Field name="name" label="Имя" placeholder="Имя" required />
                    <Field
                        name="surname"
                        label="Фамилия"
                        placeholder="Фамилия"
                        required
                    />
                    <Field
                        name="lastname"
                        label="Отчество"
                        placeholder="Отчество"
                        required
                    />
                    <Field
                        name="phone"
                        type="tel"
                        label="Номер телефона"
                        placeholder="+79999999999"
                        required
                    />
                    <Field name="email" type="email" label="E-mail" required />
                    <ButtonsContainer>
                        <Button type="submit" disabled={submitting}>
                            Сохранить
                        </Button>
                    </ButtonsContainer>
                </>
            )}
        </EditForm>
    );
};

export default withAuth(withContainer(OperatorEditForm, OPERATOR_EDIT_PROPS));
