import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import Button from 'ui/Button/Button';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import FormContainer from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { generateInvoice } from 'api/documents/documents';
import { makeCreateFormFunc } from 'utils/api';
import { Form } from 'react-final-form';
import { FormRow } from 'ui/Form/Form.styled';
import { STATIC_DOCUMENTS_PROPS } from '../constants';
import { PaymentInvoiceFormData } from 'api/documents/documents.types';
import { OrganizationTitleField } from 'components/fields/OrganizationField';

type Data = PaymentInvoiceFormData;

const Field = FormField<Data>;

const handleFormSubmit = makeCreateFormFunc(generateInvoice, 'Файл загружен');

const PaymentInvoiceForm = () => {
    return (
        <Form onSubmit={handleFormSubmit}>
            {({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                    <Headline>Счет на оплату</Headline>

                    <FormRow>
                        <Field
                            name="dateStart"
                            label="Дата начала обучения"
                            type="date"
                        />
                        <Field
                            name="dateEnd"
                            label="Дата окончания обучения"
                            type="date"
                        />
                    </FormRow>
                    <Field name="number" label="Номер" />
                    <OrganizationTitleField />

                    <ButtonsContainer>
                        <Button
                            view="word"
                            type="submit"
                            disabled={submitting}
                            name="output"
                            value="word"
                        >
                            Скачать Word
                        </Button>
                        <Button
                            view="pdf"
                            type="submit"
                            disabled={submitting}
                            name="output"
                            value="pdf"
                        >
                            Скачать PDF
                        </Button>
                    </ButtonsContainer>
                </FormContainer>
            )}
        </Form>
    );
};

export default withAuth(
    withContainer(PaymentInvoiceForm, STATIC_DOCUMENTS_PROPS)
);
