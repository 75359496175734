import { ResponseCodeEnum } from 'api/api';

export const API_URL = `${process.env.API_URL || 'https://kursoved.pro/api'}`;

enum FrontendResponseCodes {
    Error = 'Error'
}

export const ResponseCodes = { ...FrontendResponseCodes, ...ResponseCodeEnum };

export type ResponseCode = keyof typeof ResponseCodes;

export const MAIN_BLOCK_ID = 'main';
