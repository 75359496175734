import styled, { css } from 'styled-components';
import { themeable } from 'themes/utils';
import { numberToPx } from 'utils/css';
import { isColumnOptions } from './utils';
import { ColumnOptions } from './Table.types';

export const Wrapper = styled.div`
    width: 100%;
`;

// Styled контейнер для всего списка
export const Contaner = styled.table<{
    $columns?: Record<string, ColumnOptions | string | undefined>;
    $paddingTop?: boolean;
}>`
    width: max-content;
    min-width: 100%;
    flex: 1;
    display: block;
    box-sizing: border-box;
    padding-bottom: 43px;

    ${({ $paddingTop }) =>
        $paddingTop &&
        css`
            padding-top: 24px;
        `}

    ${({ $columns }) =>
        $columns &&
        Object.keys($columns).map((key) => {
            const options = $columns[key];
            if (isColumnOptions(options)) {
                return css`
                    & .td-${key}, & .th-${key} {
                        ${options.width &&
                        css`
                            width: ${numberToPx(options.width)};
                            flex: unset;
                        `}
                    }
                `;
            }
        })}
`;

export const Body = styled.tbody`
    width: 100%;
    display: block;
`;

// Styled элемент списка
export const Row = styled.tr`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${themeable('secondaryBackground')};
    border-radius: 8px;
    min-width: 200px;
    min-height: 90px;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 16px;
`;

// Styled контейнер для столбцов
export const Cell = styled.td`
    display: block;
    flex: 1;
    text-align: center; /* Выравнивание текста по центру */
    min-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 16px;
`;

// Styled контейнер для заголовков
export const Head = styled.thead`
    display: block;
    margin: 0 0 20px;
    position: sticky;
    top: 0;
`;

export const BeforeHeaderCell = styled.th`
    width: 100%;
`;

export const HeadRow = styled.tr`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    min-width: 200px;
    box-sizing: border-box;
`;

// Styled элемент заголовка колонки
export const HeadingCell = styled.th`
    font-weight: bold;
    color: ${themeable('textColor')};
    padding: 0 8px;
    flex: 1;
    min-width: 100px;
    text-align: center;
    display: block;
`;

export const photoCellMixin = css`
    min-width: unset;
    width: 60px;
`;
