export function lowerCaseFirst(string: string) {
    if (string.length === 0) {
        return string;
    }

    return string[0].toLowerCase() + string.slice(1);
}

export function lowerCaseAll(string: string) {
    if (string.length === 0) {
        return string;
    }

    return string
        .split('.')
        .map((part) => part[0].toLowerCase() + part.slice(1))
        .join('.');
}
