import { get, postWithToast } from 'utils/fetch';
import * as Types from './documents.types';
import {
    CertificateDocModel,
    CertificateEducationModel,
    DiplomDocModel,
    TicketBModel
} from 'api/api';

export const getCertificateInfo = (id: number) =>
    get<CertificateDocModel>(`DocumentCourse/GetCertificateInfo/${id}`);

export const generateCertificate = (props: Types.CertificateGenerateData) =>
    postWithToast('DocumentCourse/Certificate', props, { download: true });

export const getDiplomInfo = (id: number) =>
    get<DiplomDocModel>(`DocumentCourse/GetDiplomInfo//${id}`);

export const generateDiplom = (props: DiplomDocModel) =>
    postWithToast('DocumentCourse/Diplom', props, { download: true });

export const generateCertificateOfStudy = (props: CertificateEducationModel) =>
    postWithToast<CertificateEducationModel>(
        `Document/CertificateEducation`,
        props,
        {
            download: true
        }
    );

// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
export const generateInvoice = (props: any) =>
    postWithToast(`Document/PaymentInvoice`, props, {
        download: true
    });

export const getTicketBInfo = (id: number) =>
    get<TicketBModel>(`DocumentCourse/GetTicketBInfo/${id}`);

export const generateTicketB = (props: Types.CertificateGenerateData) =>
    postWithToast('DocumentCourse/TicketB', props, { download: true });
