/* eslint-disable @typescript-eslint/no-explicit-any */
import { usePopup, usePopupClose } from 'components/Popup';
import {
    FC,
    forwardRef,
    useCallback,
    useContext,
    useRef,
    useState
} from 'react';
import { useField } from 'react-final-form';
import FormField from 'ui/FormField/FormField';
import { TextL } from 'ui/Styled/Styled';
import 'react-advanced-cropper/dist/style.css';
import {
    ArbitraryProps,
    DefaultSize,
    FixedCropper,
    FixedCropperRef,
    ImageRestriction,
    StencilSize
} from 'react-advanced-cropper';
import { FileData } from 'api/file/file.types';
import Button from 'ui/Button/Button';
import { uploadFileAsync } from 'api/file/file';
import { FormContext } from 'ui/FormContainer/FormContainer';
import * as S from './PhotoField.styled';
import { FormRow } from 'ui/Form/Form.styled';

const stencilProps: ArbitraryProps = {
    handlers: false,
    lines: false,
    resizable: false
};

const stencilSize: StencilSize = {
    width: 300,
    height: 400
};

type PhotoPopupProps = {
    src: string;
    onUpload(file: FileData): void;
    onChange(src: string): void;
};

const defaultSize: DefaultSize = ({ imageSize, visibleArea }) => {
    return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height
    };
};

const PhotoPopupContent: FC<PhotoPopupProps> = ({
    src,
    onUpload,
    onChange
}) => {
    const [loading, setLoading] = useState(false);
    const ref = useRef<FixedCropperRef | null>(null);
    const closePopup = usePopupClose();
    const { loadingFiles } = useContext(FormContext);

    const rotateLeft = useCallback(() => {
        if (ref.current) {
            ref.current.rotateImage(-90);
        }
    }, []);

    const rotateRight = useCallback(() => {
        if (ref.current) {
            ref.current.rotateImage(90);
        }
    }, []);

    const uploadCroppedImage = useCallback(() => {
        const image = ref.current?.getCanvas();

        if (image) {
            setLoading(true);
            onChange(image.toDataURL('image/jpeg'));

            image.toBlob(async (blob) => {
                if (blob) {
                    loadingFiles.push(
                        uploadFileAsync(blob).then((data) => {
                            onUpload(data);

                            setLoading(false);
                            return data;
                        })
                    );

                    closePopup();
                } else {
                    throw new Error('Error while image.toBlob');
                }
            });
        }
    }, [closePopup, loadingFiles, onChange, onUpload]);

    return (
        <S.PopupContainer>
            <S.PopupHeader>
                <S.LeftRotateButton onClick={rotateLeft} />
                <TextL>Фото профиля</TextL>

                <S.RightRotateButton onClick={rotateRight} />
            </S.PopupHeader>
            <S.PopupPhotoContainer>
                <FixedCropper
                    ref={ref}
                    src={src}
                    stencilProps={stencilProps}
                    stencilSize={stencilSize}
                    imageRestriction={ImageRestriction.stencil}
                    defaultSize={defaultSize}
                />
            </S.PopupPhotoContainer>

            <Button onClick={uploadCroppedImage} disabled={loading}>
                Сохранить
            </Button>
        </S.PopupContainer>
    );
};

type MakePhotoProps = {
    onSubmit(): void;
};

// eslint-disable-next-line react/display-name
const MakePhotoPopupContent = forwardRef<HTMLVideoElement, MakePhotoProps>(
    ({ onSubmit }, ref) => {
        const close = usePopupClose();
        const onClick = useCallback(() => {
            onSubmit();
            close();
        }, [onSubmit, close]);

        return (
            <>
                <S.MakePhotoContainer>
                    <S.MakePhotoVideo ref={ref} />
                </S.MakePhotoContainer>
                <S.MakePhotoButton onClick={onClick}>
                    Сделать фото
                </S.MakePhotoButton>
            </>
        );
    }
);

export const PhotoField = () => {
    const [photoSrc, setPhotoSrc] = useState<string>();
    const [originalSrc, setOriginalSrc] = useState<string>();
    const field = useField<FileData>('image');

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const streamRef = useRef<MediaStream>();

    const { loadingFiles } = useContext(FormContext);

    const getMakePhotoPopupContent = useCallback(() => {
        const makePhoto = () => {
            if (videoRef.current) {
                const canvas = document.createElement('canvas');

                const width = videoRef.current.clientWidth;
                const height = videoRef.current.clientHeight;

                canvas.width = width;
                canvas.height = height;
                canvas
                    .getContext('2d')
                    ?.drawImage(videoRef.current, 0, 0, width, height);

                streamRef.current?.getTracks().forEach((track) => {
                    if (track.readyState == 'live') {
                        track.stop();
                    }
                });

                const url = canvas.toDataURL('image/jpeg');
                setOriginalSrc(url);
                setPhotoSrc(url);

                canvas.toBlob((blob) => {
                    if (blob) {
                        loadingFiles.push(uploadFileAsync(blob));
                    } else {
                        throw new Error(`Error while canvas to blob`);
                    }
                });

                canvas.remove();
            }
        };

        return <MakePhotoPopupContent ref={videoRef} onSubmit={makePhoto} />;
    }, [loadingFiles]);

    const openMakePopup = usePopup(getMakePhotoPopupContent);

    const startStream = useCallback(async () => {
        openMakePopup();

        navigator.mediaDevices
            .getUserMedia({
                audio: false,
                video: true
            })
            .then((stream) => {
                streamRef.current = stream;
                if (videoRef.current) {
                    videoRef.current.srcObject = streamRef.current;
                    videoRef.current.play();
                }
            });
    }, [openMakePopup]);

    const getPopupContent = useCallback(() => {
        if (!originalSrc) {
            throw new Error('No photo src for showing popup');
        }

        const onUpload = (data: FileData) => {
            field.input.onChange(data);
        };

        const onSrcChange = (src: string) => {
            setPhotoSrc(src);
        };

        return (
            <PhotoPopupContent
                onUpload={onUpload}
                onChange={onSrcChange}
                src={originalSrc}
            />
        );
    }, [field.input, originalSrc]);

    const openAvatarPopup = usePopup(getPopupContent);

    const onChange = useCallback((file: File) => {
        const fr = new FileReader();

        const onLoad = () => {
            if (fr.result) {
                setPhotoSrc(fr.result as string);
                setOriginalSrc(fr.result as string);
            } else {
                throw new Error(
                    'Error while loading photo from uploading file'
                );
            }
        };

        fr.addEventListener('load', onLoad);

        fr.readAsDataURL(file);

        return () => fr.removeEventListener('load', onLoad);
    }, []);

    const downloadImage = useCallback(() => {
        if (!photoSrc) {
            return;
        }

        const link = document.createElement('a');
        link.href = photoSrc;
        link.target = '_blank';

        const filename = 'photo.jpg';

        if (filename) {
            link.download = filename;
        }

        link.click();
        link.remove();
    }, [photoSrc]);

    return (
        <>
            <FormRow>
                <FormField<any>
                    name="image"
                    type="file"
                    fileType="image"
                    label="Фото профиля"
                    onChange={onChange}
                />
                <S.FieldButton onClick={startStream}>
                    Сделать фото
                </S.FieldButton>
            </FormRow>

            {photoSrc && (
                <>
                    <S.Photo onClick={openAvatarPopup} src={photoSrc} />
                    <S.SButton onClick={downloadImage}>Скачать</S.SButton>
                </>
            )}
        </>
    );
};
