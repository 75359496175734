import { CreateCourseFormData } from 'api/courses/courses.types';
import { FC, useCallback } from 'react';
import { Select } from './fields';
import { CreateDisciplinePopupContent } from './CreateDisciplinePopup';
import { AddPopupContentFunc, SelectLoaderFunc } from 'ui/Select/Select.types';
import { getOrganizationDisciplines } from 'api/disciplines/disciplines';

const FIELD_NAME: keyof CreateCourseFormData = 'disciplineIds';

export const DisciplineField: FC = () => {
    const addPopupContent = useCallback<AddPopupContentFunc<number>>(
        (props, addOrSetOption) => (
            <CreateDisciplinePopupContent
                {...props}
                onCreate={(discipline) =>
                    addOrSetOption({
                        label: discipline.title,
                        value: discipline.id
                    })
                }
            />
        ),
        []
    );

    const loader = useCallback<SelectLoaderFunc<number>>(
        async () =>
            (await getOrganizationDisciplines()).map((item) => ({
                value: item.id,
                label: item.title
            })),
        []
    );

    return (
        <Select
            required
            multiple
            loader={loader}
            name={FIELD_NAME}
            addButtonContent="Добавить дисциплину"
            addPopupContent={addPopupContent}
            label="Перечень дисциплин"
        />
    );
};
