import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast, putWithToast } from '../../utils/fetch';
import * as Types from './students.types';
import { getCurrentOrganizationId } from 'context/User';
import { OptionsGenerator } from 'ui/Select/Select.types';
import { StudentModel, UpdateStudentModelForOper } from 'api/api';

export const getAllStudents = (search?: string) =>
    get<Types.StudentsResponse>('Operator/GetStudentsByOrganizations', {
        search
    });

export const getStudents = (
    search?: string,
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<Types.StudentsResponse>(`Organization/${organizationId}/GetStudents`, {
        search
    });

export const getStudentsOptions: OptionsGenerator<typeof getStudents> = (
    ...args
) =>
    getStudents(...args).then(({ items }) =>
        items.map((item) => ({ label: item.fullName, value: item.id }))
    );

export const createStudent = (props: Types.CreateStudentFormData) =>
    postWithToast('Operator/RegisterStudentForOperator', props);

export const getStudentById = (studentId: string) =>
    get<StudentModel>(`Operator/GetStudentById/${studentId}`);

export const getStudentByIdForUpdate = async (
    studentId: string
): Promise<Types.UpdateStudentFormData> => {
    const { user, organization, ...data } = await get<StudentModel>(
        `Operator/GetStudentById/${studentId}`
    );

    return {
        ...user,
        organizationId: organization?.id,
        ...data
    } as Types.UpdateStudentFormData;
};

export const getAssignCourse = (studentId: Types.StudentId = 'current') =>
    get<Types.AssignedCourse[]>(`AssignCourse/GetAssigns/${studentId}`);

export const getMoodle = (studentId: Types.StudentId = 'current') =>
    get<Types.MoodleData>(`Moodle/GetLogPas/${studentId}`);

export const editStudent = (props: UpdateStudentModelForOper) =>
    putWithToast(`Operator/UpdateStudent`, props);
