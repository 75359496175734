import { CreateCourseFormData } from 'api/courses/courses.types';
import { FC, useCallback, useState } from 'react';
import { Select } from './fields';
import { CreateDisciplinePopupContent } from './CreateDisciplinePopup';
import { AddPopupContentFunc, OptionProps } from 'ui/Select/Select.types';
import { getOrganizationDisciplinesOptions } from 'api/disciplines/disciplines';
import { DisciplineInfo } from 'api/disciplines/disciplines.types';
import { pluralize } from 'utils/strings';

const FIELD_NAME: keyof CreateCourseFormData = 'disciplineIds';
const DEFAULT_LABEL = 'Перечень дисциплин';

export const DisciplineField: FC = () => {
    const [label, setLabel] = useState<React.ReactNode>(DEFAULT_LABEL);

    const addPopupContent = useCallback<AddPopupContentFunc<number>>(
        (props, addOrSetOption) => (
            <CreateDisciplinePopupContent
                {...props}
                onCreate={(discipline) =>
                    addOrSetOption({
                        label: discipline.title,
                        value: discipline.id,
                        data: discipline
                    })
                }
            />
        ),
        []
    );

    const onChange = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (_: any, disciplines: OptionProps<number, DisciplineInfo>[]) => {
            setLabel(
                DEFAULT_LABEL +
                    (disciplines.length > 0
                        ? ` (${pluralize(
                              disciplines.reduce(
                                  (val, discipline) =>
                                      val + (discipline.data?.hoursCount ?? 0),
                                  0
                              ),
                              ['час', 'часа', 'часов']
                          )})`
                        : '')
            );
        },
        []
    );

    return (
        <Select
            required
            multiple
            loader={getOrganizationDisciplinesOptions}
            name={FIELD_NAME}
            addButtonContent="Добавить дисциплину"
            addPopupContent={addPopupContent}
            onChange={onChange}
            label={label}
        />
    );
};
