import {
    CertificateDocModel,
    IssuedDocumentType,
    CertificateEducationModel,
    ContractModel
} from 'api/api';

export type CertificateGenerateData = CertificateDocModel;
export type CertificateEducationFormData = CertificateEducationModel;

export { IssuedDocumentType as AssignmentDocumentType };

export enum StaticDocument {
    CertificateOfStudy = 'certificate-of-study',
    PaymentInvoice = 'payment-invoice'
}

export type PaymentInvoiceFormData = ContractModel;
