import styled from 'styled-components';
import { Input } from '../Input/Input.styled';

export const SearchField = styled(Input)`
    width: 100%;
    z-index: 10;
    margin-top: 24px;
    position: sticky;
    top: 0;
    left: 0;
`;

export const Container = styled.div`
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column;

    margin: 0 -24px;
    padding: 0 24px;
`;
