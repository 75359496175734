import {
    mapOrganizationOption,
    mapOrganizationTitleOption
} from 'api/organizations/organizations.mapper';
import {
    useOrganizationsOptions,
    useOrganizationsRefresh,
    useOrganizationsTitleOptions
} from 'context/Organizations';
import {
    useCurrentOrganizationOption,
    useCurrentOrganizationTitleOption
} from 'context/User';
import { FC, useCallback } from 'react';
import { SelectField } from 'ui/Select';

type OrganizationFieldProps = {
    name?: string;
    setCurrent?: boolean;
};

export const OrganizationField: FC<OrganizationFieldProps> = ({
    name,
    setCurrent = true
}) => {
    const options = useOrganizationsOptions();
    const option = useCurrentOrganizationOption();
    const refresh = useOrganizationsRefresh();
    const loader = useCallback(
        async (search?: string) => {
            const items = await refresh(search);
            return items.map(mapOrganizationOption);
        },
        [refresh]
    );

    return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <SelectField<any, number>
            name={name || 'organizationId'}
            label="Организация"
            placeholder="Название организации"
            loader={loader}
            options={options}
            required
            defaultOption={setCurrent ? option : undefined}
        />
    );
};

export const OrganizationTitleField: FC<OrganizationFieldProps> = ({
    name,
    setCurrent = true
}) => {
    const options = useOrganizationsTitleOptions();
    const option = useCurrentOrganizationTitleOption();

    const refresh = useOrganizationsRefresh();
    const loader = useCallback(
        async (search?: string) => {
            const items = await refresh(search);
            return items.map(mapOrganizationTitleOption);
        },
        [refresh]
    );

    return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <SelectField<any, string>
            name={name || 'organizationTitle'}
            label="Организация"
            placeholder="Название организации"
            options={options}
            loader={loader}
            required
            defaultOption={setCurrent ? option : undefined}
        />
    );
};
