import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import { AssignedCourse } from 'api/courses/courses.types';
import { PowerTable } from 'ui/PowerTable';
import { ColumnProps, ParseTableColumns } from 'ui/Table';
import { getAssignedCourses } from 'api/courses/courses';
import { formatDate } from 'utils/date';
import { RouterPaths } from 'helpers/router-paths';
import { getStudentFullname } from 'utils/fullname';

const ASSIGNED_COURSES_COLUMNS: ColumnProps<AssignedCourse> = {
    'student.user.name': 'ФИО',
    'course.title': 'Название курса',
    dateStartLearning: 'Начало обучения',
    dateEndLearning: 'Конец обучения'
};

const parseColumns: ParseTableColumns<AssignedCourse> = {
    'student.user.name': (_, row) => getStudentFullname(row.student),
    dateStartLearning: formatDate,
    dateEndLearning: formatDate
};

const AssignedCoursesList = () => {
    return (
        <>
            <Headline $disableMargin>Список назначенных курсов</Headline>
            <PowerTable
                columns={ASSIGNED_COURSES_COLUMNS}
                getter={getAssignedCourses}
                parseColumns={parseColumns}
                linkGetter={RouterPaths.CourseAssignment}
            />
        </>
    );
};

export default withAuth(withContainer(AssignedCoursesList));
