/* eslint-disable @typescript-eslint/no-explicit-any */
import { toast } from 'react-toastify';
import { API_URL, ResponseCodes } from '../helpers/constants';
import { getMessage } from '../messages';
import { FetchResult, FetchError, FormErrors } from '../typings/fetch';
import { lowerCaseAll } from './strings';

type RequestProps = RequestInit & {
    download?: boolean;
};

export type FetchCallback<
    ReturnModel = any,
    RequestModel = any,
    ErrorReturn = any
> = (
    onSuccess?: (response: FetchResult<ReturnModel>) => any,
    onError?: (response: FetchError<RequestModel>) => ErrorReturn
) => Promise<ErrorReturn>;

export function mapToFormErrors<RequestModel = any>(
    res: FetchError<RequestModel>
): undefined | FormErrors<RequestModel> {
    return (
        res.fields &&
        (Object.entries(res.fields).reduce(
            (current, [field, value]) => ({
                ...current,
                [lowerCaseAll(field)]: value
            }),
            {}
        ) as FormErrors<RequestModel>)
    );
}

export function fetchAPI<
    ReturnModel = unknown,
    RequestModel = unknown,
    ErrorReturn = any
>(
    input: string,
    init?: RequestProps,
    showMessage = true
): FetchCallback<ReturnModel, RequestModel, ErrorReturn> {
    const isFormData = init?.body instanceof FormData;

    return (onSuccess, onError) => {
        const throwError = (res: FetchError<RequestModel>) => {
            if (res.msg) {
                console.error(res.msg);
            }

            if (showMessage) {
                toast.error(res.msg || getMessage(res.code));
            }

            return onError?.(res) ?? mapToFormErrors(res);
        };

        return fetch(`${API_URL}/${input}`, {
            credentials: 'include',
            ...(init || {}),
            headers: {
                ...(isFormData ? {} : { 'Content-type': 'application/json' }),
                ...(init?.download ? {} : { Accept: 'application/json' }),
                ...(init?.headers ?? {})
            }
        })
            .then(async (res) => {
                const contentType = res.headers.get('Content-type');

                const response: Maybe<FetchResult<ReturnModel>> =
                    contentType?.includes('application/json')
                        ? await res.json()
                        : null;

                if (!res.ok || (response && !response.ok)) {
                    return throwError(
                        (response as FetchError<RequestModel>) ?? {
                            code: ResponseCodes.Error,
                            ok: false
                        }
                    );
                }

                if (init?.download) {
                    const blob = await res.blob();

                    if (!blob) {
                        throw new Error(`No blob in fetch result`);
                    }

                    const url = URL.createObjectURL(blob);

                    const link = document.createElement('a');
                    link.href = url;
                    link.target = '_blank';

                    const header = res.headers.get('Content-Disposition');

                    if (!header) {
                        throw new Error(`No Content-Disposition header`);
                    }

                    const parts = header.split(';');
                    const filename = parts[1].split('=')[1];

                    if (filename) {
                        link.download = filename;
                    }

                    link.click();
                    link.remove();

                    URL.revokeObjectURL(url);
                }

                return (
                    onSuccess?.(
                        response ??
                            ({
                                ok: true,
                                code: ResponseCodes.Ok
                            } as FetchResult<ReturnModel>)
                    ) || undefined
                );
            })
            .catch((e) => {
                return throwError({
                    code: ResponseCodes.Error,
                    ok: false,
                    msg: e
                });
            });
    };
}

export function post<ErrorReturn = any, ReturnModel = any, RequestModel = any>(
    input: string,
    body: RequestModel | FormData, // Учитываем, что body может быть и FormData
    init?: Omit<RequestProps, 'method'>,
    showMessage?: boolean
) {
    const isFormData = body instanceof FormData;

    return fetchAPI<ReturnModel, RequestModel, ErrorReturn>(
        input,
        {
            body: isFormData ? body : JSON.stringify(body), // Если это FormData, не сериализуем
            method: 'POST',
            ...(init || {})
        },
        showMessage
    );
}

export function postWithToast<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(input: string, body: RequestModel, init?: Omit<RequestProps, 'method'>) {
    return post<ErrorReturn, ReturnModel, RequestModel>(
        input,
        body,
        init,
        true
    );
}

export function get<ReturnModel = string>(
    input: string,
    params?: Record<string, string | number | undefined>,
    init?: RequestProps
) {
    const filteredParams =
        params &&
        Object.entries(params).reduce<Record<string, string>>(
            (prev, [key, value]) => ({
                ...prev,
                ...(value ? { [key]: value.toString() } : {})
            }),
            {}
        );

    const paramsString =
        filteredParams && new URLSearchParams(filteredParams).toString();

    return new Promise<ReturnModel>((resolve, reject) => {
        fetchAPI<ReturnModel>(
            `${input}${paramsString ? `?${paramsString}` : ''}`,
            init,
            false
        )(
            (response) => {
                resolve(response.data as ReturnModel);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

export function put<ErrorReturn = any, ReturnModel = any, RequestModel = any>(
    input: string,
    body?: RequestModel,
    init?: Omit<RequestProps, 'method'>,
    showMessage?: boolean
) {
    return fetchAPI<ReturnModel, RequestModel, ErrorReturn>(
        input,
        {
            body: JSON.stringify(body),
            method: 'PUT', // Указываем метод PUT
            ...(init || {})
        },
        showMessage
    );
}

export function putWithToast<
    ErrorReturn = any,
    ReturnModel = any,
    RequestModel = any
>(input: string, body?: RequestModel, init?: Omit<RequestProps, 'method'>) {
    return put<ErrorReturn, ReturnModel, RequestModel>(
        input,
        body,
        init,
        true // Показывать уведомления
    );
}
