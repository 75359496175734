import styled from 'styled-components';

const ButtonsContainerRow = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
`;

export default ButtonsContainerRow;
