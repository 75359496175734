export const isoDateWithoutTime = (date: string) => date.substring(0, 10);

export function formatDate(date?: unknown) {
    // Проверяем, существует ли дата окончания курса
    if (date) {
        const dateObj = new Date(String(date));

        // Проверяем, является ли дата действительной
        if (!isNaN(dateObj.getTime())) {
            return dateObj.toLocaleDateString('en-GB');
        } else {
            return 'Некорректная дата';
        }
    }

    return 'Дата не указана';
}

export const cutDateTime = (date: Date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

export const formatBirthdayDate = (date?: unknown) => {
    if (date) {
        const dateObj = new Date(String(date));

        // Проверяем, является ли дата действительной
        if (!isNaN(dateObj.getTime())) {
            return dateObj.toLocaleDateString('ru-RU');
        } else {
            return 'Некорректная дата';
        }
    }

    return 'Дата не указана';
};
