import {
    getOrganizationById,
    editOrganization
} from 'api/organizations/organizations'; // Импортируем наши запросы

import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import Button from 'ui/Button/Button';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import FormField from 'ui/FormField/FormField';
import { CreateOrganizationFormData } from 'api/organizations/organizations.types';
import { Headline } from 'ui/Styled/Styled';
import { useWithNumberId } from 'hooks/useId';
import { AddressField } from 'components/fields/AddressField';
import { ORGANIZATION_EDIT_PROPS } from '../constants';

const Field = FormField<CreateOrganizationFormData>;

const OrganizationEditForm = () => {
    const getter = useWithNumberId(getOrganizationById);

    return (
        <EditForm
            getter={getter} // Получаем данные организации
            saver={editOrganization} // Сохраняем изменения
            successMessage="Организация успешно обновлена"
        >
            {({ submitting }) => (
                <>
                    <Headline>Редактировать организацию</Headline>
                    <Field
                        name="title"
                        label="Название организации"
                        placeholder='ООО "Курсовед"'
                        required
                    />
                    <Field
                        name="phone"
                        type="tel"
                        label="Номер телефона"
                        placeholder="+79999999999"
                        required
                    />
                    <Field name="email" type="email" label="E-mail" required />
                    <Field
                        name="inn"
                        type="number"
                        required
                        label="ИНН"
                        placeholder="ИНН"
                    />
                    <AddressField />

                    <Field
                        name="bankAccountNumber"
                        type="number"
                        maxLength={20}
                        minLength={20}
                        required
                        label="Номер банковского счета (для выставления счетов)"
                        placeholder="40702810200210000237"
                    />
                    <ButtonsContainer>
                        <Button type="submit" disabled={submitting}>
                            Сохранить
                        </Button>
                    </ButtonsContainer>
                </>
            )}
        </EditForm>
    );
};

export default withAuth(
    withContainer(OrganizationEditForm, ORGANIZATION_EDIT_PROPS)
);
