import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast } from '../../utils/fetch';
import * as Types from './disciplines.types';
import { ItemsEntity } from 'typings/pagination';
import { getCurrentOrganizationId } from 'context/User';

export const getOrganizationDisciplines = (
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<ItemsEntity<Types.DisciplineInfo>>(
        `Discipline/GetDisciplineListByOrgId/${organizationId}`
    ).then(({ items }) => items);

export const createDiscipline = (
    props: Types.CreateDisciplineFormData,
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    postWithToast<
        unknown,
        Types.DisciplineInfo,
        Types.CreateDisciplineFormData
    >('Discipline/AddDiscipline', {
        ...props,
        disciplineListId:
            typeof organizationId === 'number' ? organizationId : null
    });
