import { createGlobalStyle } from 'styled-components';
import dark from './themes/dark';
import * as t from './tokens';
import { themeable } from 'themes/utils';

export const GlobalStyle = createGlobalStyle`
    ${dark}

    :root {
        ${t.headlineSizeL.name}: 24px;
        ${t.containerMaxWidth.name}: 1600px;
    }

    body {
        color: ${themeable('textColor')};
        background: ${themeable('bodyBackgroundColor')};
    }
        /* Стилизация полосы прокрутки */
        ::-webkit-scrollbar {
        width: 12px; /* Ширина полосы прокрутки */
    }

    ::-webkit-scrollbar-track {
        background: ${themeable(
            'scrollbarTrackColor'
        )}; /* Цвет фона полосы прокрутки */
    }

    ::-webkit-scrollbar-thumb {
        background-color: ${themeable(
            'scrollbarThumbColor'
        )}; /* Цвет бегунка полосы прокрутки */
        border-radius: 10px; /* Радиус скругления бегунка */
        border: 3px solid ${themeable('scrollbarTrackColor')}; /* Отступ вокруг бегунка */
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: ${themeable(
            'scrollbarThumbHoverColor'
        )}; /* Цвет бегунка при наведении */
    }
`;
