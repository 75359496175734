/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react';
import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline, Title } from '../../ui/Styled/Styled';
import FormContainer from '../../ui/FormContainer/FormContainer';
import FormField from '../../ui/FormField/FormField';
import { AssignCourseFormData } from '../../api/courses/courses.types';
import { Form } from 'react-final-form';
import { assignCourse, getCoursesOptions } from '../../api/courses/courses';
import Button from '../../ui/Button/Button';
import { SelectField } from '../../ui/Select';
import { makeCreateFormFunc } from 'utils/api';
import {
    getCurrentOrganizationIdNumber,
    useCurrentOrganizationId
} from 'context/User';
import { isoDateWithoutTime } from 'utils/date';
import { generateMoodleReq, getMoodleRequisites } from 'api/moodle/moodle';
import InputInformation from 'ui/Input/InputInformation';
import { MoodleRequisites } from 'api/moodle/moodle.types';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import { getStudentsOptions } from 'api/students/students';
import { EndDateField } from './components/fields';
import { AssignOrganizationField } from './components/AssignOrganizationField';
import ButtonsContainerRow from 'ui/Button/ButtonContainerRow';

const Field = FormField<AssignCourseFormData>;
const Select = SelectField<AssignCourseFormData>;

const handleFormSubmit = makeCreateFormFunc(assignCourse, 'Курс назначен!');

const initialValues: Partial<AssignCourseFormData> = {
    dateStartLearning: isoDateWithoutTime(new Date().toISOString())
};

const AssignCoursePage = () => {
    const currentOrganizationId = useCurrentOrganizationId();

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<
        number | undefined
    >(getCurrentOrganizationIdNumber());
    const [isGenerationActive, setIsGenerationActive] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState<string>();
    const [reqDataMoodleUser, setReqDataMoodleUser] =
        useState<MoodleRequisites | null>();

    const generationReqMoodle = useCallback(async () => {
        setIsGenerationActive(false);
        let reqDataUser;
        if (selectedStudent) {
            reqDataUser = await getMoodleRequisites(selectedStudent);
            if (!reqDataUser.login && !reqDataUser.password) {
                reqDataUser = await generateMoodleReq(selectedStudent);
            }
        }
        setReqDataMoodleUser(reqDataUser);
    }, [selectedStudent]);

    const onStudentChange = useCallback((value: string) => {
        setSelectedStudent(value);
        setIsGenerationActive(true);
        setReqDataMoodleUser(null);
    }, []);

    useEffect(
        () => setSelectedOrganizationId(currentOrganizationId),
        [currentOrganizationId]
    );

    const onGetStudents = useCallback(
        (search?: string) => getStudentsOptions(search, selectedOrganizationId),
        [selectedOrganizationId]
    );

    const onGetCourses = useCallback(
        (search?: string) => getCoursesOptions(search, selectedOrganizationId),
        [selectedOrganizationId]
    );

    const onOrganizationSelect = useCallback((value: number) => {
        setSelectedOrganizationId(value);
        setIsGenerationActive(false);
    }, []);

    return (
        <>
            <Headline>Назначить курс</Headline>
            <Form<AssignCourseFormData>
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit, submitting, form }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <AssignOrganizationField
                            form={form}
                            value={selectedOrganizationId}
                            onChange={onOrganizationSelect}
                        />

                        <Select
                            name="studentId"
                            onSearch={onGetStudents}
                            onChange={onStudentChange}
                            label="Студент"
                            required
                            placeholder="Выберите студента"
                        />

                        <Select
                            name="courseId"
                            onSearch={onGetCourses}
                            label="Курс"
                            required
                        />

                        <Field
                            name="dateStartLearning"
                            label="Дата начала обучения"
                            type="date"
                            required
                        />

                        <EndDateField />

                        <ButtonsContainerRow>
                            <Title>Данные для Moodle:</Title>
                            <Button
                                disabled={!isGenerationActive}
                                onClick={generationReqMoodle}
                            >
                                Сгенерировать
                            </Button>
                        </ButtonsContainerRow>

                        {!reqDataMoodleUser}
                        <InputInformation
                            label="Логин"
                            value={reqDataMoodleUser?.login}
                        />
                        <InputInformation
                            label="Пароль"
                            value={reqDataMoodleUser?.password}
                        />
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonsContainer>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(AssignCoursePage));
