import { CheckAuthPageProps, withCheckAuth } from '../../context/Auth';
import { UserType } from 'api/api';
import StudentProfile from 'pages/profile/StudentProfile';
import OperatorHome from './OperatorHome';

const HomePage = ({ userType }: CheckAuthPageProps) => {
    switch (userType) {
        case UserType.Operator:
            return <OperatorHome />;
        case UserType.Student:
            return <StudentProfile />;
        default:
            throw new Error(`Unknown user type: ${userType}`);
    }
};

export default withCheckAuth(HomePage);
