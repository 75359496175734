import { UserType } from 'api/api';
import { getOrganizations } from 'api/organizations/organizations';
import {
    mapOrganizationOption,
    mapOrganizationTitleOption
} from 'api/organizations/organizations.mapper';
import { OrganizationInfo } from 'api/organizations/organizations.types';
import { useUserType } from 'context/Auth';
import {
    createContext,
    FC,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import { OptionProps } from 'ui/Select/Select.types';

type OrganizationsContextProps = {
    items?: OrganizationInfo[];
    refresh(search?: string): Promise<OrganizationInfo[]>;
};

export const OrganizationsContext = createContext<OrganizationsContextProps>({
    refresh() {
        throw new Error('Empty refresh method');
    }
});

export const OrganizationsProvider: FC<ChildrenProps> = ({ children }) => {
    const [items, setItems] = useState<OrganizationInfo[]>();
    const userType = useUserType();

    useEffect(() => {
        if (userType === UserType.Operator) {
            getOrganizations().then(({ items }) => setItems(items));
        }
    }, [userType]);

    const refresh = useCallback(
        async (search?: string) => {
            if (userType !== UserType.Operator) {
                throw new Error('Invalid user type for refresh organizatinos');
            }

            const { items: newItems } = await getOrganizations(search);

            setItems(newItems);

            return newItems;
        },
        [userType]
    );

    const value = useMemo(() => ({ items, refresh }), [items, refresh]);

    return (
        <OrganizationsContext.Provider value={value}>
            {children}
        </OrganizationsContext.Provider>
    );
};

export const useOrganizations = () => useContext(OrganizationsContext).items;

export const useOrganizationsOptions = ():
    | OptionProps<number>[]
    | undefined => {
    const items = useOrganizations();

    return items?.map(mapOrganizationOption);
};

export const useOrganizationsTitleOptions = ():
    | OptionProps<string>[]
    | undefined => {
    const items = useOrganizations();

    return items?.map(mapOrganizationTitleOption);
};

export const useOrganizationsRefresh = () =>
    useContext(OrganizationsContext).refresh;
