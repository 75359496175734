import { AssignCourseFormData } from 'api/courses/courses.types';
import { useOrganizationsOptions } from 'context/Organizations';
import { useCurrentOrganizationOption } from 'context/User';
import { FormApi } from 'final-form';
import { FC, useCallback } from 'react';
import { RawSelect } from 'ui/Select';

type AssignOrganizationFieldProps = {
    form: FormApi<AssignCourseFormData>;
    value?: number;
    onChange(value: number): void;
};

export const AssignOrganizationField: FC<AssignOrganizationFieldProps> = ({
    form,
    value,
    onChange
}) => {
    const defaultOption = useCurrentOrganizationOption();
    const options = useOrganizationsOptions();

    const handleChange = useCallback(
        (value: number) => {
            onChange(value);
            form.change('courseId', undefined);
            form.change('studentId', undefined);
        },
        [form, onChange]
    );

    return (
        <RawSelect
            name="organizationId"
            label="Организация"
            options={options}
            required
            value={value}
            defaultOption={defaultOption}
            onChange={handleChange}
        />
    );
};
