import {
    ChangeEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

import { Field, FieldInputProps } from 'react-final-form';
import * as S from './Select.styled';
import { OptionProps, SelectFormFieldProps, SelectProps } from './Select.types';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useSetFalse, useSetTrue } from '../../hooks/booleans';
import { useValidators } from '../../hooks/useValidators';
import { Dropdown, DropdownMultiple } from './components/Dropdown';
import { AddOption } from './components/AddOption';
import { PopupChildrenRenderProps } from 'components/Popup';
import { useEffectOnce } from 'hooks/useEffectOnce';

export function RawSelect<OptionValue>({
    input,
    meta,
    onChange,
    value: fieldValue,
    options: rawOptions,
    placeholder,
    label,
    multiple,
    onSearch,
    keepSearch,
    addButtonContent,
    addPopupContent,
    loader,
    getLabel,
    search = true,
    defaultOption,
    ...compareProps
}: SelectProps<OptionValue>) {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [freezed, setFreezed] = useState(false);

    const freeze = useSetTrue(setFreezed);
    const unfreeze = useSetFalse(setFreezed);

    const [options, setOptions] = useState(rawOptions);
    const [shouldScroll, setShouldScroll] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (shouldScroll) {
            setTimeout(() => {
                if (dropdownRef.current) {
                    dropdownRef.current.scrollTop =
                        dropdownRef.current.scrollHeight;
                }
                setShouldScroll(false);
            });
        }
    }, [shouldScroll]);

    const close = useSetFalse(setIsOpen);

    const value = useMemo(
        () => input?.value ?? fieldValue,
        [input, fieldValue]
    );

    const [filteredOptions, setFilteredOptions] = useState<
        OptionProps<OptionValue>[]
    >(options || []);

    const valueWithOption: Maybe<OneOrMany<OptionProps<OptionValue>>> =
        useMemo(() => {
            if (!value) {
                return defaultOption;
            }

            if (getLabel) {
                return Array.isArray(value)
                    ? value.map((val) => ({ label: getLabel(val), value: val }))
                    : { value, label: getLabel(value) };
            }

            const finalOptions =
                options && options.length > 0 ? options : filteredOptions;

            return Array.isArray(value)
                ? finalOptions.length > 0
                    ? finalOptions.filter((opt) =>
                          value.some((val) => opt.value == val)
                      )
                    : defaultOption
                      ? [defaultOption]
                      : undefined
                : finalOptions.find((opt) => opt.value == value) ||
                      defaultOption;
        }, [defaultOption, filteredOptions, getLabel, options, value]);

    useEffectOnce(() => {
        if (!value && defaultOption) {
            onChange?.(defaultOption.value as OptionValue & OptionValue[]);
            input?.onChange?.(defaultOption.value);
        }

        if (
            valueWithOption &&
            !Array.isArray(valueWithOption) &&
            valueWithOption.label &&
            keepSearch
        ) {
            setSearchValue(valueWithOption.label);
        }
    });

    useEffect(() => {
        loader?.().then((value) => {
            setOptions(value);
        });
    }, [loader]);

    useEffect(() => {
        setOptions(rawOptions);
    }, [rawOptions]);

    useEffect(() => {
        onSearch?.(searchValue).then(setFilteredOptions);
    }, [onSearch, searchValue]);

    useEffect(() => {
        if (!onSearch) {
            setFilteredOptions(
                options?.filter(
                    (option) =>
                        option.label
                            ?.toLowerCase()
                            .includes(searchValue.toLowerCase()) ||
                        String(option.value)
                            .toLowerCase()
                            .includes(searchValue.toLowerCase())
                ) || []
            );
        }
    }, [searchValue, options, onSearch]);

    const addOrSetOption = useCallback(
        (option: OptionProps<OptionValue>) => {
            const newValue = multiple
                ? [
                      ...((value || []) as OptionProps<OptionValue>[]),
                      option.value
                  ]
                : option;

            onChange?.(newValue as OptionValue & OptionValue[]);
            input?.onChange?.(newValue);

            setOptions([...(options || []), option]);
            setShouldScroll(true);
            unfreeze();
        },
        [input, multiple, onChange, options, unfreeze, value]
    );

    const inputProps = useMemo<FieldInputProps<string, HTMLElement>>(
        () => ({
            value: searchValue,
            name: input?.name || Symbol().toString(),
            onBlur:
                input?.onBlur ??
                (() => {
                    return;
                }),
            onFocus: (event) => {
                if (!loading) {
                    setIsOpen(true);
                    input?.onFocus?.(event);
                }
            },
            onChange: (event: ChangeEvent<HTMLInputElement>) => {
                setSearchValue(event.target.value);
            }
        }),
        [input, loading, searchValue]
    );

    const onDropdownChange = useCallback(
        (newOption: OneOrMany<OptionProps<OptionValue>>) => {
            if (!multiple && !keepSearch) {
                close();
                setSearchValue('');
            }
            if (
                keepSearch &&
                !Array.isArray(newOption) &&
                (newOption.label || String(newOption.value))
            ) {
                setSearchValue(newOption.label || String(newOption.value));
            }

            const newValue = Array.isArray(newOption)
                ? newOption.map((val) => val.value)
                : newOption.value;

            const change = onChange?.(newValue as OptionValue & OptionValue[]);

            if (change && change instanceof Promise) {
                setLoading(true);
                change.then(() => {
                    setLoading(false);
                });
            }

            input?.onChange?.(newValue);
        },
        [onChange, close, keepSearch, multiple, input]
    );

    const onClickOutside = useCallback(() => {
        if (!freezed) {
            close();
            if (
                multiple &&
                !keepSearch &&
                (value as OptionValue[]).length > 0
            ) {
                setSearchValue('');
            }
        }
    }, [freezed, close, multiple, keepSearch, value]);

    const dropdownRef = useRef<HTMLDivElement>(null);

    const ref = useClickOutside(onClickOutside, [dropdownRef], isOpen);

    const [preparedPlaceholder, fillPlaceholder] = useMemo(() => {
        const getValue = () => {
            if (Array.isArray(valueWithOption)) {
                return valueWithOption
                    .map(({ label, value }) => label || value)
                    .join(', ');
            } else {
                return valueWithOption
                    ? (valueWithOption.label ?? String(valueWithOption.value))
                    : null;
            }
        };

        const valuePlaceholder = getValue();

        return [
            valuePlaceholder ||
                placeholder ||
                (search ? 'Поиск...' : 'Не выбрано'),
            !!valuePlaceholder
        ];
    }, [placeholder, valueWithOption, search]);

    const addPopupContentGetter = useCallback(
        (props: PopupChildrenRenderProps) =>
            addPopupContent && addPopupContent(props, addOrSetOption),
        [addOrSetOption, addPopupContent]
    );

    return (
        <S.Container ref={ref}>
            <S.Input
                input={inputProps}
                placeholder={preparedPlaceholder}
                meta={meta ?? {}}
                label={label}
                $isOpen={isOpen}
                $fillPlaceholder={fillPlaceholder}
                readOnly={!search}
            />
            {filteredOptions.length > 0 && (
                <S.Dropdown $isOpen={isOpen} ref={dropdownRef}>
                    {multiple ? (
                        <DropdownMultiple<OptionValue>
                            options={filteredOptions}
                            value={
                                valueWithOption as OptionProps<OptionValue>[]
                            }
                            onChange={onDropdownChange}
                            {...compareProps}
                        />
                    ) : (
                        <Dropdown<OptionValue>
                            options={filteredOptions}
                            value={valueWithOption as OptionProps<OptionValue>}
                            onChange={onDropdownChange}
                            {...compareProps}
                        />
                    )}
                    {addPopupContent && (
                        <AddOption
                            onOpenPopup={freeze}
                            onClosePopup={unfreeze}
                            popupContent={addPopupContentGetter}
                            label={addButtonContent}
                        />
                    )}
                </S.Dropdown>
            )}
        </S.Container>
    );
}

export function SelectField<FormData, OptionValue = unknown>({
    name,
    validators: rawValidators,
    multiple,
    onChange,
    ...selectProps
}: SelectFormFieldProps<FormData, OptionValue>) {
    const validators = useValidators(rawValidators, { ...selectProps });

    if (multiple) {
        return (
            <Field<OptionValue[]>
                validate={validators}
                name={name}
                type="select"
                multiple
                render={(props) => (
                    <RawSelect<OptionValue>
                        multiple
                        onChange={onChange}
                        {...props}
                        {...selectProps}
                    />
                )}
            />
        );
    }

    return (
        <Field<OptionValue>
            validate={validators}
            name={name}
            type="select"
            render={(props) => (
                <RawSelect<OptionValue>
                    onChange={onChange}
                    {...props}
                    {...selectProps}
                />
            )}
        />
    );
}
