import { OptionProps } from 'ui/Select/Select.types';
import { get } from '../../utils/fetch';
import * as Types from './professions.types';

export const getProfessions = (search?: string) =>
    get<Types.ProfessionInfo[]>('Course/GetProfessions', {
        search
    });

export const getProfessionsOptions = (search?: string) =>
    getProfessions(search).then((items) =>
        items.map<OptionProps<number>>((item) => ({
            value: item.id,
            label: item.title
        }))
    );

export const getProfessionsTitleOptions = (search?: string) =>
    getProfessions(search).then((items) =>
        items.map<OptionProps>((item) => ({
            value: item.title,
            label: item.title
        }))
    );
