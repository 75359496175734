import rotateIcon from 'assets/rotate.svg';
import styled from 'styled-components';
import { themeable } from 'themes/utils';

import cropIcon from 'assets/crop.png';
import Button from 'ui/Button/Button';
import { inputLabelMargin } from 'tokens';

export const PopupContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 21px;
`;

export const PopupPhotoContainer = styled.div`
    position: relative;
    border-radius: 8px;
    border: 2px solid #c4c4c4;
    user-select: none;
    width: 100%;
    height: 400px;
    overflow: hidden;

    & .advanced-cropper {
        height: 100%;
    }
`;

export const PopupHeader = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const LeftRotateButton = styled.div`
    cursor: pointer;

    &::before {
        content: url(${rotateIcon});
    }
`;

export const RightRotateButton = styled(LeftRotateButton)`
    transform: scaleX(-1);
`;

export const Photo = styled.img`
    object-fit: cover;
    height: 240px;
    width: 180px;
    margin-bottom: 6px;
    cursor: url(${cropIcon}), nwse-resize;
    border: 2px solid ${themeable('primaryColor')};
`;

export const SButton = styled(Button)`
    margin-bottom: 15px;
    width: 180px;
`;

export const FieldButton = styled(Button)`
    margin-top: ${inputLabelMargin};
    flex: unset;
`;

export const MakePhotoContainer = styled.div`
    position: relative;
    width: 100%;
    aspect-ratio: 3 / 4;
    overflow: hidden;
    border: 2px solid #c4c4c4;
`;

export const MakePhotoVideo = styled.video`
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const MakePhotoButton = styled(Button)`
    margin: 12px auto 0;
    display: block;
`;
