import styled from 'styled-components';
import { themeable } from 'themes/utils';

export const ContainerTopContent = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
`;
export const ContainerLeftContent = styled.div`
    display: flex;
    gap: 46px;
`;
export const WrapperRow = styled.div`
    display: flex;
`;
export const ContainerRightContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
`;
export const ContainerMainInformation = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
`;

export const ButtonsRow = styled.div`
    margin-top: 10px;
`;

export const TabMenuWrapper = styled.div`
    margin-bottom: 10px;
`;
export const ContainerField = styled.div`
    padding-bottom: 24px;
    overflow: auto;
    height: 100%;
`;

export const InformationRow = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background: ${themeable('secondaryBackground')};
    border-radius: 8px;
    box-sizing: border-box;
    margin-top: 16px;
    padding: 22px 14px;
    color: rgb(113, 142, 191);
    justify-content: space-between;
`;

export const InformationData = styled.div`
    display: flex;
    gap: 24px;
    align-items: center;
`;

export const InformationRowInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;
