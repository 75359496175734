import * as S from './Profile.styled';
import Button from 'ui/Button/Button';
import { PowerTable } from 'ui/PowerTable';
import { ColumnProps } from 'ui/Table';
import { OrganizationInfo } from 'api/organizations/organizations.types';
import { getOrganizations } from 'api/organizations/organizations';
import Skeleton from 'components/Skeleton/Skeleton';
import { getCurrentOperators } from '../../api/organizations/operators';
import { CurrentOperator } from 'api/organizations/operators.types';
import { logoutUser } from 'api/auth/auth';
import Tabs from 'ui/Tabs/Tabs';
import { RouterPaths } from 'helpers/router-paths';
import { Link } from 'react-router-dom';
import { useUser } from 'context/User';
import { getUserFullname } from 'utils/fullname';
import { TextL } from 'ui/Styled/Styled';
import { HeaderDataRow } from './DataRow';
import withContainer from 'components/Container/withContainer';
import { withAuth } from 'context/Auth';

type OrganizatonRowProps = Pick<OrganizationInfo, 'title' | 'inn'>;

const ORGANIZATIONS_COLUMNS: ColumnProps<OrganizatonRowProps> = {
    title: 'Название организации',
    inn: 'ИНН'
};

const OPERATORS_COLUMNS: ColumnProps<CurrentOperator> = {
    fullName: 'ФИО',
    email: 'Email'
};

const ProfileOperator = () => {
    const user = useUser();

    if (!user) {
        return <Skeleton />;
    }

    return (
        <>
            <S.ContainerTopContent>
                <S.ContainerLeftContent>
                    <S.ContainerMainInformation>
                        <TextL>{getUserFullname(user)}</TextL>
                        <HeaderDataRow label="E-mail" value={user.email} />
                        <HeaderDataRow
                            label="Организация"
                            value={
                                user.operator?.organization?.title ??
                                'Не указана'
                            }
                        />
                    </S.ContainerMainInformation>
                </S.ContainerLeftContent>
                <S.ContainerRightContent>
                    <Link to={RouterPaths.OperatorEdit(user.id)}>
                        <Button size={'s'}>Редактировать</Button>
                    </Link>
                    <Button size="s" onClick={logoutUser}>
                        Выход
                    </Button>
                </S.ContainerRightContent>
            </S.ContainerTopContent>

            <Tabs
                tabs={[
                    {
                        label: 'Операторы',
                        content: (
                            <PowerTable
                                columns={OPERATORS_COLUMNS}
                                getter={getCurrentOperators}
                                noRowsMsg="Операторы не найдены"
                                search={false}
                                paddingTop
                            />
                        )
                    },
                    {
                        label: 'Организации',
                        content: (
                            <PowerTable
                                columns={ORGANIZATIONS_COLUMNS}
                                getter={getOrganizations}
                                noRowsMsg="Организации не найдены"
                                search={false}
                                linkGetter={RouterPaths.OrganizationProfile}
                                paddingTop
                            />
                        )
                    }
                ]}
            />
        </>
    );
};

export default withAuth(withContainer(ProfileOperator));
