import { FC } from 'react';
import RawCalendar from 'react-calendar';
import { CalendarProps } from './Calendar.types';

export const Calendar: FC<CalendarProps> = ({ value, onChange }) => {
    return (
        <RawCalendar
            formatMonthYear={(locale, date) =>
                date.toLocaleString(locale, { month: 'long' }) +
                ' ' +
                date.getFullYear()
            }
            value={value}
            onClickDay={onChange}
        />
    );
};
