import { getCommitteeById, updateCommittee } from 'api/committees/committees';
import {
    CommitteeFullInfo,
    CreateCommitteeFormData
} from 'api/committees/committees.types';
import withContainer from 'components/Container/withContainer';
import { RouterPaths } from 'helpers/router-paths';
import { useEffect, useState } from 'react';
import { Headline } from 'ui/Styled/Styled';
import Skeleton from 'components/Skeleton/Skeleton';
import Button from 'ui/Button/Button';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import FormContainer from 'ui/FormContainer/FormContainer';
import RawFormField from 'ui/FormField/FormField';
import { Form } from 'react-final-form';
import { AddComissionMember } from 'components/AddComissionMember';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { makeCreateFormFunc } from 'utils/api';

const FormField = RawFormField<CreateCommitteeFormData>;
import { FormApi } from 'final-form';
import { useNumberId } from 'hooks/useId';

const ComissionUpdatePage = () => {
    const [comission, setComission] = useState<CommitteeFullInfo>();
    const id = useNumberId();

    useEffect(() => {
        getCommitteeById(id).then(setComission);
    }, [id]);

    if (!comission) {
        return <Skeleton />;
    }

    const handleFormSubmit = (
        values: CreateCommitteeFormData & { id: string },
        form: FormApi<CreateCommitteeFormData>
    ) => {
        const dataToSend = {
            id: values.id,
            title: values.title,
            members: values.members // Убедись, что члены комиссии передаются правильно
        } as CreateCommitteeFormData;

        // Вызов makeCreateFormFunc с добавлением функции обновления состояния
        return makeCreateFormFunc(updateCommittee, 'Комиссия изменена!')(
            dataToSend,
            form
        ).then(() => {
            setComission((prevComission) => ({
                ...prevComission, // Сохраняем старые данные
                members: values.members, // Обновляем список участников
                title: values.title // Обновляем название
            }));
        });
    };

    return (
        <>
            <Headline>Редактировать {comission.title}</Headline>

            <Form
                onSubmit={(values, form) =>
                    handleFormSubmit({ ...values, id }, form)
                }
                initialValues={{
                    id,
                    title: comission.title,
                    members: comission.members
                }} // Оставляем members для предзаполнения
                mutators={{ ...arrayMutators }}
            >
                {({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <FormField
                            name="title"
                            label="Название комиссии"
                            required
                        />
                        <Headline>Участники комиссии</Headline>
                        <FieldArray
                            render={AddComissionMember}
                            name="members"
                        />
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Сохранить
                            </Button>
                        </ButtonsContainer>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withContainer(ComissionUpdatePage, {
    parentPath: RouterPaths.FindComission,
    additionalItem: { path: '', text: 'Просмотр комиссии' }
});
