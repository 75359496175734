import { PopupChildrenRenderProps } from 'components/Popup';
import { FormApi } from 'final-form';
import { FC, useCallback } from 'react';
import { Form } from 'react-final-form';
import Button from 'ui/Button/Button';
import FormContainer from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import {
    CreateDisciplineFormData,
    DisciplineInfo
} from 'api/disciplines/disciplines.types';
import { createDiscipline } from 'api/disciplines/disciplines';

type FormType = CreateDisciplineFormData;

const PopupField = FormField<FormType>;

export type OnCreateFunc = (value: DisciplineInfo) => void;

type CreateDisciplinePopupProps = PopupChildrenRenderProps & {
    onCreate: OnCreateFunc;
};

export const CreateDisciplinePopupContent: FC<CreateDisciplinePopupProps> = ({
    close,
    onCreate
}) => {
    const onSubmit = useCallback(
        (data: FormType, form: FormApi<FormType>) => {
            createDiscipline(data)((discipline) => {
                if (!discipline.data) {
                    throw new Error(
                        `Undefined discipline's data in API's return`
                    );
                }

                onCreate(discipline.data);
                close();
                form.restart();
            });
        },
        [close, onCreate]
    );

    return (
        <Form onSubmit={onSubmit}>
            {({ handleSubmit, submitting, valid }) => (
                <FormContainer onSubmit={valid ? handleSubmit : undefined}>
                    <PopupField
                        name="title"
                        label="Название"
                        placeholder="Название дисциплины"
                        required
                    />
                    <PopupField
                        name="hoursCount"
                        label="Количество часов"
                        type="number"
                        placeholder="Количество часов"
                        required
                    />

                    <Button type="submit" disabled={submitting || !valid}>
                        Добавить
                    </Button>
                </FormContainer>
            )}
        </Form>
    );
};
