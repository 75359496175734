import { postWithToast, get, putWithToast } from '../../utils/fetch';
import * as Types from './organizations.types';
import { makePromisable } from 'utils/api';

export const createOrganization = (props: Types.CreateOrganizationFormData) =>
    postWithToast('Organization/AddOrganization', props);

export const getOrganizations = (search?: string) =>
    get<Types.OrganizationsResponse>('Operator/GetOrganizations', { search });

export const getOrganizationById = (organizationId: number) =>
    get<Types.OrganizationFullInfo>(`Organization/${organizationId}`);

export const editOrganization = (props: Types.OrganizationFullInfo) =>
    putWithToast<Types.OrganizationFullInfo>(
        'Organization/UpdateOrganization',
        props
    );

export const changeOrganization = makePromisable(
    (id: number) =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        putWithToast<any, void>(`Operator/UpdateCurrentOrganization/${id}`),
    null
);
