import styled, { css } from 'styled-components';
import { S } from 'ui/Select';
import RadioOff from '../../assets/radio-off.svg';
import RadioOn from '../../assets/radio-on.svg';

export const Container = styled(S.DropdownBase)``;

export const RadioItem = styled(S.DropdownItem)`
    &::after {
        ${({ $active }) => css`
            background-image: url(${$active ? RadioOn : RadioOff});
        `}
    }
`;

export const Input = styled.input`
    display: none;
`;
