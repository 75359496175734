import * as Types from './courses.types';

export const formatCourseInfoForForm = ({
    disciplines,
    profession,
    ...info
}: Types.CourseInfo): Types.CreateCourseFormData => ({
    ...info,
    disciplineIds: disciplines?.map((e) => Number(e.id)),
    professionId: profession?.id
});
