import { useCallback } from 'react';
import {
    Field,
    FieldRenderProps,
    useForm,
    useFormState
} from 'react-final-form';
import Button from 'ui/Button/Button';
import { ButtonProps } from 'ui/Button/Button.types';
import ButtonsContainer from 'ui/Button/ButtonContainer';

export type ButtonOptionProps<T> = Partial<ButtonProps> & {
    value: T;
    children: React.ReactNode;
};

type BaseButtonFieldProps<T> = {
    options: ButtonOptionProps<T>[];
};

type ButtonsFieldRenderProps<T> = FieldRenderProps<T> & BaseButtonFieldProps<T>;

type ButtonsFieldProps<T> = {
    name: string;
} & BaseButtonFieldProps<T>;

type ButtonComponentProps<T> = {
    button: ButtonOptionProps<T>;
    submitting: boolean;
    submit(): void;
    onChange(value: T): void;
};

function ButtonOption<T>({
    button,
    submit,
    onChange,
    submitting
}: ButtonComponentProps<T>) {
    const onClick = useCallback(() => {
        onChange(button.value);
        button.onClick?.();

        submit();
    }, [button, onChange, submit]);

    return <Button onClick={onClick} disabled={submitting} {...button} />;
}

function ButtonsFieldRender<T>({ input, options }: ButtonsFieldRenderProps<T>) {
    const { submit } = useForm();
    const { submitting } = useFormState();

    return (
        <ButtonsContainer>
            {options.map((option) => (
                <ButtonOption
                    button={option}
                    key={option.value}
                    submit={submit}
                    submitting={submitting}
                    onChange={input.onChange}
                />
            ))}
        </ButtonsContainer>
    );
}

export function ButtonsField<T>({ name, ...baseProps }: ButtonsFieldProps<T>) {
    const render = useCallback(
        (props: FieldRenderProps<T>) => {
            return <ButtonsFieldRender {...props} {...baseProps} />;
        },
        [baseProps]
    );

    return <Field name={name} render={render} />;
}
