import React from 'react';

import { GlobalStyle } from './GlobalStyles';

import './App.css';
import { AuthProvider } from './context/Auth';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { ToastContainer } from './components/ToastContainer/ToastContainer';
import { UserProvider } from './context/User';
import { ThemeProvider } from 'context/Theme/Theme.context';
import { OrganizationsProvider } from 'context/Organizations';
function App() {
    return (
        <AuthProvider>
            <UserProvider>
                <OrganizationsProvider>
                    <ThemeProvider>
                        <GlobalStyle />
                        <RouterProvider router={router} />
                        <ToastContainer />
                    </ThemeProvider>
                </OrganizationsProvider>
            </UserProvider>
        </AuthProvider>
    );
}

export default App;
