/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    FieldArrayProps,
    FieldArray as RawFieldArray
} from 'react-final-form-arrays';

type Props<
    T = any,
    FieldValue = any,
    Elem extends HTMLElement = HTMLElement
> = FieldArrayProps<FieldValue, Elem> & {
    name: NonNullable<Paths<T>>;
};

export function FieldArray<T = any>(props: Props<T>) {
    return <RawFieldArray {...props} />;
}
