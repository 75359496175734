import { postWithToast } from 'utils/fetch';
import { FileData } from './file.types';
import { makePromisable } from 'utils/api';

export const uploadFile = (file: File | Blob) => {
    const data = new FormData();
    data.set('file', file);
    return postWithToast<unknown, FileData>('/File/Save', data);
};

export const uploadFileAsync = makePromisable(uploadFile, null);
