import styled, { css } from 'styled-components';
import { lightTheme } from 'themes/light';
import { forDarkTheme, forLightTheme } from 'themes/utils';
import { Link } from 'react-router-dom';
import { animationDuration } from 'tokens';
import { CalendarBaseContainer } from 'components/Calendar/Calendar.styled';

export const Sidebar = styled.aside`
    display: flex;
    flex-flow: column;
    background: ${({ theme }) => theme.sidebar.background};
    min-height: 100%;
    border-radius: 16px;

    transition: background-color ${animationDuration};
`;

export const BrandContainer = styled.div`
    padding: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.sidebar.lineColor};
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    transition: border-color ${animationDuration};
`;

export const MenuContainer = styled.nav`
    flex: 1;
    padding: 24px;
`;

export const MenuLabel = styled.div`
    color: ${({ theme }) => theme.sidebar.menuLabelColor};
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.4px;
    margin: 0 12px 8px;
    text-transform: uppercase;
`;

export const ThemeChoiceContainer = styled.div`
    padding: 28px;
    border-top: 1px solid ${({ theme }) => theme.sidebar.lineColor};

    transition: border-color ${animationDuration};
`;

export const ThemeChoicesRow = styled.div`
    display: flex;
    flex-flow: row;
    margin: 0 auto;

    ${forDarkTheme(css`
        background: #161a23;
    `)}

    ${forLightTheme(css`
        border: 1px solid ${lightTheme.sidebar.lineColor};
    `)}

    width: max-content;

    border-radius: 6px;
`;

export const ThemeChoice = styled.button<SActiveProps>`
    flex: 1;
    border-radius: 6px;
    padding: 8px 14px;
    display: flex;
    flex-flow: row;
    gap: 8px;
    color: ${({ theme }) => theme.sidebar.lineColor};
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.02em;
    background: none;
    outline: none;
    appearance: none;
    border: none;
    cursor: pointer;
    transition-duration: ${animationDuration};
    transition-property: all;

    ${({ $active, theme }) =>
        $active &&
        (theme.name === 'dark'
            ? css`
                  background: #2d303e;
                  border: 1px solid ${theme.sidebar.lineColor};
              `
            : css`
                  border-right: 1px solid ${theme.sidebar.lineColor};
                  background: ${theme.sidebar.menuItemBackground};
              `)}

    & svg * {
        transition: fill 0.2s;
        fill: ${({ theme }) => theme.sidebar.lineColor};
    }
`;

export const ThemeIcon = styled.div`
    max-width: 16px;
    max-height: 16px;
    width: auto;
    height: auto;
`;

export const MainLink = styled(Link)`
    display: flex;
    align-items: center;
`;

export const CalendarContainer = styled(CalendarBaseContainer)`
    top: 0;
    left: 100%;
    margin-left: 54px;
`;

export const Wrapper = styled.div`
    padding: 24px;
`;
