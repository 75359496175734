import { OptionProps } from 'ui/Select/Select.types';
import { OrganizationInfo } from './organizations.types';

export const mapOrganizationOption = (
    item: OrganizationInfo
): OptionProps<number> => ({ label: item.title, value: item.id });

export const mapOrganizationTitleOption = (
    item: OrganizationInfo
): OptionProps<string> => ({ label: item.title, value: item.title });
