import { CommitteeMemberType } from 'api/committees/committees.types';
import { AssignedQualificationType } from '../api/courses/courses.types';
import { ResponseCode } from '../helpers/constants';
import {
    DisciplineGradeType,
    EducationType,
    IssuedDocumentType,
    TrainingType
} from 'api/api';
import { StaticDocument } from 'api/documents/documents.types';

export const messages: Partial<Record<ResponseCode, string>> = {
    Error: 'Произошла ошибка, повторите попытку позже',
    Ok: 'Успешно!',
    AuthException: 'Неверный логин или пароль',
    Unauthorized: 'Вы не авторизованы',
    Invalid: 'Проверьте правильность заполнения полей',
    EmailAlreadyUse: 'Этот E-mail уже используется другим пользователем.'
};

const trainingTypes: Record<TrainingType, string> = {
    ProfessionalTraining: 'Профессиональное образование (ПО)',
    AdvancedTraining: 'Повышение квалификации (ПК)',
    ProfessionalRetraining: 'Профессиональная переподготовка (ПП)'
};

const qualificationTypes: Record<AssignedQualificationType, string> = {
    MayBeAdmitted: 'Может быть допущен',
    PersonnelCategory: 'Категория персонала',
    Rank: 'Разряд'
};

const issuedDocuments: Record<IssuedDocumentType, string> = {
    Diplom: 'Диплом',
    Certificate: 'Свидетельство',
    TicketB: 'Удостоверение Б',
    TicketM: 'Удостоверение М',
    Protocol: 'Протокол'
};

const staticDocuments: Record<StaticDocument, string> = {
    [StaticDocument.PaymentInvoice]: 'Счет на оплату',
    [StaticDocument.CertificateOfStudy]: 'Справка об обучении'
};

const committeeMemberType: Record<CommitteeMemberType, string> = {
    Chairman: 'Председатель',
    SubChairman: 'Заместитель председателя',
    MemberCommittee: 'Член комиссии'
};

const educationTypes: Record<EducationType, string> = {
    High: 'Высшее',
    MiddleCommon: 'Среднее общее',
    MiddleProfessional: 'Среднее профессиональное'
};

export const gradeTypes: Record<DisciplineGradeType, string> = {
    Excelent: 'Отлично',
    Good: 'Хорошо',
    Accepted: 'Зачтено'
};

export default {
    messages,
    trainingTypes,
    qualificationTypes,
    issuedDocuments,
    committeeMemberType,
    educationTypes,
    gradeTypes,
    staticDocuments
};
