import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { getStudents } from '../../api/students/students';
import { StudentInfo } from '../../api/students/students.types';
import { ColumnProps, ParseTableColumns } from '../../ui/Table';
import { PowerTable } from 'ui/PowerTable';
import { Headline } from 'ui/Styled/Styled';
import { RouterPaths } from 'helpers/router-paths';
import { formatDate } from 'utils/date';
import { NoAvatar } from 'ui/Menu/assets/ItemsIcons';
import styled from 'styled-components';

const AvatarContainer = styled.div`
    display: flex;
    justify-content: center; /* Центрирование по горизонтали */
    align-items: center; /* Центрирование по вертикали */
    width: 60px; /* Установите ширину контейнера */
    height: 60px; /* Установите высоту контейнера */
    overflow: hidden; /* Обрезает изображение, выходящее за границы */
    background-color: rgb(240, 240, 240); /* Цвет фона для пустых аватаров */
    border-radius: 8px;
    border: 1px solid #718ebf;
`;

const AvatarImage = styled.img`
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Обеспечивает заполнение контейнера изображением */
`;

const STUDENTS_COLUMNS: ColumnProps<StudentInfo> = {
    avatar: { title: '', width: 60 },
    fullName: 'ФИО',
    phone: 'Номер телефона',
    birthday: 'Дата рождения',
    email: 'Почта'
};

const parseColumns: ParseTableColumns<StudentInfo> = {
    birthday: formatDate,

    avatar: (avatar) => {
        const url = avatar?.url ?? null;
        return (
            <AvatarContainer>
                {url ? (
                    <AvatarImage src={url} alt="Avatar" />
                ) : (
                    <NoAvatar /> // Компонент для отсутствующего аватара
                )}
            </AvatarContainer>
        );
    }
};

const StudentsList = () => {
    return (
        <>
            <Headline $disableMargin>Студенты</Headline>
            <PowerTable
                columns={STUDENTS_COLUMNS}
                getter={getStudents}
                linkGetter={RouterPaths.StudentProfile}
                parseColumns={parseColumns}
                noRowsMsg="Студенты не найдены"
            />
        </>
    );
};

export default withAuth(withContainer(StudentsList));
