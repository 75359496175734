import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SubmissionError } from '../../typings/fetch';
import * as S from './Input.styled';
import { RawInputProps } from './Input.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputProps = FieldRenderProps<any, HTMLElement, any> &
    Partial<HTMLInputElement> &
    RawInputProps;

const Input: FC<InputProps> = ({
    label,
    required,
    minLength,
    maxLength,
    readOnly,
    disabled,
    placeholder,
    meta,
    className,
    rightContent,
    mask,
    input,
    name: rawName,
    center,
    errors
}) => {
    const name = rawName || input.name;

    const extractedProps = {
        required,
        minLength,
        maxLength,
        readOnly,
        disabled,
        name
    };

    return (
        <S.Container>
            <S.Label>{label}</S.Label>
            <S.InputRow>
                <S.InputContainer className={className}>
                    <S.Input
                        $center={center}
                        mask={mask}
                        placeholder={placeholder || label}
                        {...extractedProps}
                        {...input}
                    />
                </S.InputContainer>
                {rightContent}
            </S.InputRow>
            {meta.touched &&
                (meta.error ||
                    ((meta.submitError || errors) &&
                        !meta.dirtySinceLastSubmit)) && (
                    <S.Meta>
                        {meta.error ||
                            (meta.submitError || errors)?.map(
                                ({ code, message }: SubmissionError) => (
                                    <div key={code}>{message}</div>
                                )
                            )}
                    </S.Meta>
                )}
        </S.Container>
    );
};

export default Input;
