import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast, putWithToast } from '../../utils/fetch';
import * as Types from './courses.types';
import { getCurrentOrganizationId } from 'context/User';
import { AssignedCourse } from './courses.types';
import { OptionsGenerator } from 'ui/Select/Select.types';

export const createCourse = (props: Types.CreateCourseFormData) =>
    postWithToast('Course/CreateCourse', props);

export const getCourses = (
    search?: string,
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<Types.CoursesResponse>(`Course/GetCoursesByOrgId/${organizationId}`, {
        search
    });

export const getCoursesOptions: OptionsGenerator<typeof getCourses> = (
    ...args
) =>
    getCourses(...args).then(({ items }) =>
        items.map((item) => ({ label: item.title, value: item.id }))
    );

export const getCourseById = (courseId: number) =>
    get<Types.CourseInfo>(`Course/${courseId}`);

export const editCourse = (props: Types.CreateCourseFormData) =>
    putWithToast<Types.CreateCourseFormData>('Course/UpdateCourse', props);

export const assignCourse = (props: Types.AssignCourseFormData) =>
    postWithToast('AssignCourse/AddAssignCourse', props);

export const getCourseDateEndLearning = (courseId: number, startDate: string) =>
    get('AssignCourse/GetDateEndLearning', { courseId, startDate });

export const getAssignedCourses = (
    _search?: string,
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<AssignedCourse[]>(
        `AssignCourse/GetOrganizationAssigns/${organizationId}`
    );

export const getCourseAssignment = (id: number) =>
    get<AssignedCourse>(`AssignCourse/${id}`);
