import styled from 'styled-components';

export const FormRow = styled.div`
    display: flex;
    flex-flow: row;
    gap: 10px;

    & > * {
        flex: 1;
    }
`;

export const FileFieldContainer = styled.div`
    display: flex;
    align-items: end;
    padding-bottom: 14px;

    & > * {
        width: 100%;
    }
`;
