import { getCourseDateEndLearning } from 'api/courses/courses';
import {
    AssignCourseFormData,
    CreateCourseFormData
} from 'api/courses/courses.types';
import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import FormField from 'ui/FormField/FormField';
import { SelectField } from 'ui/Select';
import { isoDateWithoutTime } from 'utils/date';

export const Field = FormField<CreateCourseFormData>;
export const Select = SelectField<CreateCourseFormData>;

export const EndDateField = () => {
    const form = useForm<AssignCourseFormData>();

    const {
        values: { dateStartLearning, courseId }
    } = useFormState<AssignCourseFormData>();

    useEffect(() => {
        const fetchDate = async () => {
            if (dateStartLearning && courseId) {
                form.change(
                    'dateEndLearning',
                    isoDateWithoutTime(
                        await getCourseDateEndLearning(
                            courseId,
                            dateStartLearning
                        )
                    )
                );
            }
        };

        fetchDate();
    }, [form, dateStartLearning, courseId]);

    return (
        <FormField<AssignCourseFormData>
            name="dateEndLearning"
            label="Дата окончания обучения"
            type="date"
            required
        />
    );
};
