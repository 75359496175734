import { FC } from 'react';
import * as S from './Container.styled';
import { MAIN_BLOCK_ID } from '../../helpers/constants';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { SidebarProps } from 'components/Sidebar/Sidebar.types';
import { PopupProvider } from 'components/Popup';

export type ContainerProps = ChildrenProps & {
    disablePaddingTop?: boolean;
} & SidebarProps;

const Container: FC<ContainerProps> = ({
    children,
    disablePaddingTop,
    ...sidebarProps
}) => {
    return (
        <S.Container>
            <Sidebar {...sidebarProps} />

            <S.MainContainer id={MAIN_BLOCK_ID}>
                <PopupProvider>
                    <S.Main $disablePaddingTop={disablePaddingTop}>
                        {children}
                    </S.Main>
                </PopupProvider>
            </S.MainContainer>
        </S.Container>
    );
};

export default Container;
