import { FC, useMemo } from 'react';
import { MenuItemFullProps } from '../Menu.types';
import * as S from './MenuItem.styled';
import { Link } from 'react-router-dom';
import { useTheme } from 'context/Theme/Theme.context';

import { ReactComponent as MenuDropdownIcon } from 'assets/menu-dropdown.svg';

const MenuItem: FC<MenuItemFullProps> = ({
    text,
    icon,
    whiteIcon,
    path,
    subItems: rawSubItems,
    pathname,
    additionalItem,
    activeItem
}) => {
    const theme = useTheme();

    const subItems = useMemo(
        () => [
            ...(additionalItem ? [additionalItem] : []),
            ...(rawSubItems ?? [])
        ],
        [rawSubItems, additionalItem]
    );

    const [active, activeIndex] = useMemo<[boolean, number | undefined]>(() => {
        const index = subItems?.findIndex(
            (i) => i.path === pathname || i.path === '' || i.path === activeItem
        );

        return [
            ![undefined, -1].includes(index) ||
                path === pathname ||
                path === activeItem,
            index
        ];
    }, [activeItem, path, pathname, subItems]);

    const content = useMemo(
        () => (
            <S.Container $active={active}>
                <S.IconContainer $baseIcon={!whiteIcon}>
                    {theme === 'dark' ? icon : (whiteIcon ?? icon)}
                </S.IconContainer>
                <S.Text>{text}</S.Text>
                {!!subItems?.length && (
                    <S.ChevronContainer>
                        <MenuDropdownIcon />
                    </S.ChevronContainer>
                )}
            </S.Container>
        ),
        [active, subItems, whiteIcon, theme, icon, text]
    );

    return (
        <S.Li>
            {path ? <Link to={path}>{content}</Link> : content}
            {!!subItems?.length && (
                <S.SubItemsContainer
                    $active={![undefined, -1, false].includes(active)}
                >
                    {subItems.map(({ text, path: sPath }, index) => (
                        <S.SubItemContainer
                            $active={index === activeIndex}
                            to={sPath}
                            key={text}
                        >
                            {text}
                        </S.SubItemContainer>
                    ))}
                </S.SubItemsContainer>
            )}
        </S.Li>
    );
};

export default MenuItem;
