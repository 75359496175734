import styled from 'styled-components';
import CalendarIconUrl, {
    ReactComponent as CalendarIcon
} from 'assets/calendar.svg';
import { RelativeContainer } from 'ui/Styled/Styled';
import { Input } from 'ui/Input/Input.styled';

export const CalendarButton = styled(CalendarIcon)`
    cursor: pointer;
`;

export const CalendarBaseContainer = styled.div`
    position: absolute;

    width: 357px;
    min-height: 407px;
    z-index: 11;
    border-radius: 8px;

    background: ${({ theme }) => theme.sidebar.calendarBackground};

    border-radius: 8px;

    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
    color: #f2f2f2;
    padding: 16px 0;
    box-sizing: border-box;

    & button {
        cursor: pointer;
    }

    & .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.5;
    }

    & .react-calendar__navigation__label {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 20px;
    }

    & .react-calendar__navigation {
        gap: 6px;
        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 9px 16px;
        border-bottom: 1px solid #718ebf;
    }

    & .react-calendar__viewContainer {
        padding: 11px 16px;
        font-size: 18px;
    }

    & .react-calendar__navigation__arrow {
        font-size: 20px;
        border-radius: 8px;
        height: 32px;
        width: 32px;
        display: block;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: rgb(37, 40, 54);
    }

    & .react-calendar__month-view__weekdays__weekday {
        margin-inline-end: unset !important;
        flex: 1 !important;
    }

    & .react-calendar__month-view__weekdays {
        text-transform: uppercase;
        color: #bdbdbd;
        text-align: center;
        margin-bottom: 7px;
        gap: 1px;

        & abbr {
            text-decoration: none;
        }
    }

    & .react-calendar__month-view__days {
        gap: 17px 1px;
        display: grid !important;
        grid-template-columns: repeat(7, 1fr);
    }

    & .react-calendar__tile {
        height: 32px;
        width: 32px;
        border-radius: 8px;
        text-align: center;
        margin: auto !important;
    }

    & .react-calendar__tile--active {
        background: ${({ theme }) => theme.sidebar.lineColor};
    }
`;

export const CalendarContainer = styled(CalendarBaseContainer)`
    top: 100%;
    left: 0;
`;

export const InputContainer = styled.div`
    cursor: pointer;

    & * {
        cursor: pointer !important;
    }

    & ${Input} {
        padding-left: 48px;

        &::before {
            content: ' ';
            display: block;
            position: absolute;
            background: url(${CalendarIconUrl});
            width: 32px;
            height: 32px;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
`;

export const Container = styled(RelativeContainer)`
    width: 100%;
`;
