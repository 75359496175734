import { OrganizationId } from 'api/organizations/organizations.types';
import { postWithToast, get, put } from '../../utils/fetch';
import * as Types from './committees.types';
import { getCurrentOrganizationId } from 'context/User';
import { OptionProps } from 'ui/Select/Select.types';

export const createCommittees = (props: Types.CreateCommitteeFormData) =>
    postWithToast('Committee/CreateCommittee', props);

export const getOrganizationCommittees = (
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<Types.CommitteesInfo[]>(
        `Committee/GetCommittiesByOrgId/${organizationId}`
    );

export const getCommitteesOptions = (organizationId?: OrganizationId) =>
    getOrganizationCommittees(organizationId).then((items) =>
        items.map<OptionProps>((item) => ({
            label: item.title,
            value: item.id
        }))
    );

export const getCommitteeById = (id: number) =>
    get<Types.CommitteeFullInfo>(`Committee/${id}`);

export const updateCommittee = (props: Types.CreateCommitteeFormData) =>
    put('Committee/UpdateCommittee', props);
