import { UserType } from 'api/api';
import { get, postWithToast } from '../../utils/fetch';
import * as Types from './auth.types';
import { RouterPaths } from 'helpers/router-paths';

export const login = (props: Types.AuthFormData) =>
    postWithToast(`User/Authorize`, props);

export const signupOrg = (props: Types.RegisterOrgFormData) =>
    postWithToast(`Register/OrganizationAndOperator`, props);

export const signupUser = (props: Types.RegisterUserFormData) =>
    postWithToast(`Register/Student`, props);

export const checkAuth = () => get<UserType>(`User/CheckAuth`);

export const logoutUser = async () => {
    try {
        // Отправляем запрос на выход
        const response = await fetch('https://kursoved.pro/api/User/Logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include', // Убедитесь, что куки отправляются с запросом
            body: JSON.stringify({}) // Пустое тело запроса
        });

        if (!response.ok) {
            throw new Error('Ошибка при выходе');
        }

        // Перенаправляем на страницу авторизации
        window.location.href = RouterPaths.Auth;
    } catch (error) {
        console.error('Ошибка при выходе:', error);
    }
};
