import { getAddressLabel, getDadataAddressOptions } from 'api/dadata/dadata';
import { DadataAddressInfo } from 'api/dadata/dadata.types';
import { SelectField } from 'ui/Select';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AddressFieldProps<T = Record<string, any>> = {
    name?: Paths<T>;
};

export function AddressField<T>({ name }: AddressFieldProps<T>) {
    return (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <SelectField<any, DadataAddressInfo>
            name={name || 'address'}
            required
            label="Почтовый адрес"
            placeholder="ул. Ленина, д. 10, кв. 15 г. Москва Российская Федерация 101000"
            onSearch={getDadataAddressOptions}
            getLabel={getAddressLabel}
            keepSearch
        />
    );
}
