import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import FormContainer from '../../ui/FormContainer/FormContainer';
import { Form } from 'react-final-form';
import Button from '../../ui/Button/Button';
import { makeCreateFormFunc } from 'utils/api';
import ButtonsContainer from 'ui/Button/ButtonContainer';
import FormField from 'ui/FormField/FormField';
import { RegisterOperatorModel } from 'api/api';
import { createOperator } from 'api/organizations/operators';
import { OrganizationField } from 'components/fields/OrganizationField';

type Data = RegisterOperatorModel;

const Field = FormField<Data>;
const handleFormSubmit = makeCreateFormFunc(createOperator, 'Оператор создан!');

const CreateCourse = () => {
    return (
        <>
            <Headline>Создание оператора</Headline>
            <Form onSubmit={handleFormSubmit}>
                {({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <Field
                            name="email"
                            type="email"
                            required
                            label="E-mail"
                        />
                        <Field
                            name="surname"
                            required
                            label="Фамилия"
                            placeholder="Иванов"
                        />
                        <Field
                            name="name"
                            required
                            label="Имя"
                            placeholder="Иван"
                        />
                        <Field
                            name="lastName"
                            type="text"
                            required
                            label="Отчество"
                            placeholder="Иванов"
                        />

                        <OrganizationField />
                        <ButtonsContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonsContainer>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(CreateCourse));
