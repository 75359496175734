import { css } from 'styled-components';
import { ThemeValues } from './themes.types';

const white = '#fff';

export const lightTheme: ThemeValues = {
    name: 'light',
    bodyBackgroundColor: white,
    textColor: '#000000',
    mainBackgroundColor: '#F5F7FA',
    secondaryBackground: white,

    scrollbarTrackColor: 'rgb(113, 142, 191)',
    scrollbarThumbColor: 'rgba(113, 142, 191, 0.5)',
    scrollbarThumbHoverColor: 'rgba(113, 142, 191, 0.5)',
    primaryColor: '#718EBF',

    sidebar: {
        menuLabelColor: white,
        background: '#5072A7',
        calendarBackground: '#B4D0FF',
        menuItemBackground: '#718EBF',
        fillIconColor: white,
        subItemColor: white,
        lineColor: white
    },
    input: {
        background: white
    }
};

export default css``;
