import { RouterPaths } from 'helpers/router-paths';
import { ContainerProps } from 'components/Container/Container';

export const STUDENT_EDIT_PROPS: Partial<ContainerProps> = {
    additionalItem: { text: 'Редактировать студента', path: '' },
    parentPath: RouterPaths.FindStudents
};

export const ORGANIZATION_EDIT_PROPS: Partial<ContainerProps> = {
    additionalItem: { text: 'Редактировать организацию', path: '' },
    parentPath: RouterPaths.FindOrganization
};

export const OPERATOR_EDIT_PROPS: Partial<ContainerProps> = {
    additionalItem: { text: 'Редактировать оператора', path: '' },
    parentPath: RouterPaths.Profile
};
