import { CurrentOrganizationData } from 'api/organizations/organizations.types';
import { get, putWithToast } from '../../utils/fetch';
import { SetPasswordProps, UserInfo } from './users.types';

export const getCurrentUser = () => get<UserInfo>('User/Current');

export const getCurrentOrganization = () =>
    get<CurrentOrganizationData>('Organization/Current');

export const setPassword = (password: string) =>
    putWithToast<SetPasswordProps>('User/SetPassword', { password });
