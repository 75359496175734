import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import FormContainer from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { generateCertificateOfStudy } from 'api/documents/documents';
import { makeCreateFormFunc } from 'utils/api';
import { Form } from 'react-final-form';
import { FormRow } from 'ui/Form/Form.styled';
import { SelectField } from 'ui/Select';
import { CreateStudentFormData } from 'api/students/students.types';
import { getProfessionsTitleOptions } from 'api/professions/professions';
import * as S from '../Documents.styled';
import { CertificateEducationFormData } from 'api/documents/documents.types';
import { ExtensionsButtonsField, STATIC_DOCUMENTS_PROPS } from '../constants';
import { OrganizationTitleField } from 'components/fields/OrganizationField';

const Field = FormField<CertificateEducationFormData>;
const Select = SelectField<CreateStudentFormData>;

const handleFormSubmit = makeCreateFormFunc(
    generateCertificateOfStudy,
    'Файл загружен'
);

const CertificateOfStudyForm = () => {
    return (
        <Form
            onSubmit={handleFormSubmit}
            render={({ handleSubmit }) => (
                <FormContainer onSubmit={handleSubmit}>
                    <Headline>Справка об обучении</Headline>
                    <S.DocumentSectionTitle>
                        Кому предоставляется
                    </S.DocumentSectionTitle>
                    <FormRow>
                        <Field name="jobTitle" label="Должность" />
                        <OrganizationTitleField />
                    </FormRow>
                    <FormRow>
                        <Field name="surnameRecipient" label="Фамилия" />
                        <Field name="nameRecipient" label="Имя" />
                        <Field name="lastnameRecipient" label="Отчество" />
                    </FormRow>
                    <S.DocumentSectionTitle>О курсе</S.DocumentSectionTitle>
                    <FormRow>
                        <Field
                            name="outgoingNumber"
                            label="Исходящий номер письма в СПС"
                        />
                        <Field
                            name="incomingNumber"
                            label="Номер входящего письма"
                        />
                    </FormRow>

                    <Select
                        name="courseTitle"
                        label="Название профессии (из справочника)"
                        placeholder="Выберите профессию"
                        loader={getProfessionsTitleOptions}
                        onSearch={getProfessionsTitleOptions}
                        required
                    />
                    <Field name="qualification" label="Разряд" />
                    <Field
                        name="outgoingDate"
                        label="Дата начала обучения"
                        type="date"
                    />
                    <Field
                        name="incomingDate"
                        label="Дата окончания обучения"
                        type="date"
                    />
                    <FormRow>
                        <Field name="surnameStudent" label="Фамилия студента" />
                        <Field name="nameStudent" label="Имя студента" />
                        <Field
                            name="lastnameStudent"
                            label="Отчество студента"
                        />
                    </FormRow>

                    <S.DocumentSectionTitle>Протокол</S.DocumentSectionTitle>
                    <FormRow>
                        <Field name="protocol" label="Номер протокола" />
                        <Field
                            name="dateOfIssue"
                            label="Дата окончания"
                            type="date"
                        />
                    </FormRow>

                    <ExtensionsButtonsField />
                </FormContainer>
            )}
        />
    );
};

export default withAuth(
    withContainer(CertificateOfStudyForm, STATIC_DOCUMENTS_PROPS)
);
