import styled, { css } from 'styled-components';
import { themeable } from 'themes/utils';

export const Container = styled.div`
    border-bottom: 1px solid #718ebf;

    position: sticky;
    top: 0;
    background: ${themeable('mainBackgroundColor')};
    padding-top: 24px;
`;

export const ChoicesContainer = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    width: max-content;
    gap: 48px;
`;

export const Choice = styled.div<SActiveProps>`
    cursor: pointer;
    font-weight: 500;
    padding-bottom: 10px;

    ${({ $active }) =>
        $active &&
        css`
            color: #718ebf;
            border-bottom: 3px solid #718ebf;
        `}
`;

export const Content = styled.main<SActiveProps>`
    flex: 1;
    overflow: hidden;
    margin: 0 -24px;
    padding: 0 24px;

    ${({ $active }) =>
        !$active &&
        css`
            display: none;
        `}
`;
