import { get } from 'utils/fetch';
import { DadataAddressInfo } from './dadata.types';
import { OptionProps } from 'ui/Select/Select.types';

export const getDadataAddresses = (search: string) =>
    get<DadataAddressInfo[]>('Dadata/SuggestAddress', { row: search });

export const getAddressLabel = (address: DadataAddressInfo) =>
    [
        address.postalCode,
        address.region,
        address.city,
        address.street,
        address.house,
        address.flat
    ]
        .filter(Boolean)
        .join(', ');

export const dadataInfoToOption = (
    address: DadataAddressInfo
): OptionProps<DadataAddressInfo> => {
    return { label: getAddressLabel(address), value: address };
};

export const getDadataAddressOptions = async (search: string) =>
    search
        ? (await getDadataAddresses(search)).map<
              OptionProps<DadataAddressInfo>
          >(dadataInfoToOption)
        : [];
