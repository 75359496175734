const innRegex = /^([0-9]{10})([0-9]{2}){0,1}$/;

function isValidInn(i: string) {
    if (i.match(/\D/)) return false;

    const inn = i.match(/(\d)/g);

    if (!inn) {
        return false;
    }

    if (inn.length === 10) {
        return (
            inn[9] ===
            String(
                ((2 * Number(inn[0]) +
                    4 * Number(inn[1]) +
                    10 * Number(inn[2]) +
                    3 * Number(inn[3]) +
                    5 * Number(inn[4]) +
                    9 * Number(inn[5]) +
                    4 * Number(inn[6]) +
                    6 * Number(inn[7]) +
                    8 * Number(inn[8])) %
                    11) %
                    10
            )
        );
    }

    if (inn.length === 12) {
        return (
            inn[10] ===
                String(
                    ((7 * Number(inn[0]) +
                        2 * Number(inn[1]) +
                        4 * Number(inn[2]) +
                        10 * Number(inn[3]) +
                        3 * Number(inn[4]) +
                        5 * Number(inn[5]) +
                        9 * Number(inn[6]) +
                        4 * Number(inn[7]) +
                        6 * Number(inn[8]) +
                        8 * Number(inn[9])) %
                        11) %
                        10
                ) &&
            inn[11] ===
                String(
                    ((3 * Number(inn[0]) +
                        7 * Number(inn[1]) +
                        2 * Number(inn[2]) +
                        4 * Number(inn[3]) +
                        10 * Number(inn[4]) +
                        3 * Number(inn[5]) +
                        5 * Number(inn[6]) +
                        9 * Number(inn[7]) +
                        4 * Number(inn[8]) +
                        6 * Number(inn[9]) +
                        8 * Number(inn[10])) %
                        11) %
                        10
                )
        );
    }

    return false;
}

export const innValidator = (value: unknown) =>
    typeof value !== 'string' || !innRegex.test(value) || !isValidInn(value)
        ? 'Некорректный ИНН'
        : undefined;
